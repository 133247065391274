<template>
  <div class="cadastro-usuario">
    <v-container fluid>
      <h1 class="titulo">Cadastro de concursos</h1>

      <div class="box-table">
        <v-skeleton-loader
          :loading="loadingTable"
          :transition="'fade-transition'"
          height="500"
          type="table">
          <v-data-table
            :headers="headers"
            :search="search"
            :items="listaItens"
            width="300px"
            flat
            hide-default-footer
            :loading="loadingPag"
            :items-per-page.sync="itemsPerPage"> 
            
            <template v-slot:top>
              <BrToolBar @search="buscar" :itemsSelect="listaItensSelect" :labelSelects="'Filtrar concurso'"  :configFilter="{ listaSelect: itemSelectKeys, getItens: getItens, jsonData: false }" :labelTextField="'Busca por cod do concurso:'"/>
            </template>
            
            <template v-slot:item.status="{item}">
              <!-- <span class="situacao" :style="`color: ${getColorSituacao(item.status)}`">{{ item.status}}</span> -->
              <v-chip :color="getColorSituacao(item.status)" dark>{{ item.status}}</v-chip>
            </template>

            <template v-slot:item.config.sizeLot="{item}">
              <!-- <span class="situacao" :style="`color: ${getColorSituacao(item.status)}`">{{ item.status}}</span> -->
              {{ item.config ? item.config.sizeLot : ' ' | numeral}}
            </template>

            <template v-slot:item.config.sizeLot="{item}">
              <!-- <span class="situacao" :style="`color: ${getColorSituacao(item.status)}`">{{ item.status}}</span> -->
              {{ item.config ? item.config.sizeLot : ' ' | numeral}}
            </template>

            <template v-slot:item.cuponsSold="{item}">
              <!-- <span class="situacao" :style="`color: ${getColorSituacao(item.status)}`">{{ item.status}}</span> -->
              {{ item.ascertainedMethodId === 'BINGO' ? item.qtdBingo : item.cuponsSold | numeral }}
            </template>

            <template v-slot:item.conifg="{item}">
              <!-- <span class="situacao" :style="`color: ${getColorSituacao(item.status)}`">{{ item.status}}</span> -->
              <v-chip v-if="permiteAcao($route, 'edit') && item.config" @click="updateConfigurations(item)" :color="getColorSituacao(item.config ? item.config.showResult : false)" dark>{{item.config.showResult ? 'Show': 'Off' }}</v-chip>
              <v-chip v-else :color="getColorSituacao(item.config ? item.config : false)" dark>{{item.config ? 'Show': 'Off' }}</v-chip>
            </template>

            <template v-slot:item.action="{ item }">
              <v-btn
                v-if="permiteAcao($route, 'edit')"
                small
                @click="editItem(item)"
                tile
                outlined 
                :color="variables.colorPrimary"
                class="mr-2"
              >
                Editar
              </v-btn>

              <v-btn
                v-if="permiteAcao($route, 'delete')"
                small
                @click="deleteItem(item)"
                tile
                outlined 
                :color="variables.colorError"
              >
                Excluir
              </v-btn>
            </template>

            <template v-slot:footer>
              <div class="wrapper-footer">
                <div class="qtd-paginacao">
                  {{ pageStart }}
                  -
                  {{ pageStop }}
                  de
                  {{ totalItens }}
                </div>
                <v-pagination
                  v-model="page"
                  :length="numberOfPages"
                  circle
                  :disabled="disabled"
                  :total-visible="7"
                  class="box-table-paginacao"
                  v-if="numberOfPages > 1"></v-pagination>
              </div>
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>

      <v-btn class="mx-2 fab-cadastrar-usuario" fab color="primary" @click="cadastrar">
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { filter } from 'lodash'

import Events from '@/core/service/events'
import { errorSnackbar, successSnackbar } from '@/core/service/utils'
import moment from 'moment'

/**
 * MIXIN
 */
import listagemTables from '@/views/mixins/listagemTables'

const namespaceStore = 'sweepstakes'

export default {
  name: 'Cadastro',
  components: {
    BrToolBar: () => import('./components/toolbar')
  },
  mixins: [listagemTables],
  data: () => ({
    headers: [
      { align: 'start', class: 'table-header', text: 'ID', value: 'id' },
      { align: 'start', class: 'table-header', text: 'COD', value: 'cod' },
      { align: 'start', class: 'table-header', text: 'Tipo', value: 'ascertainedMethodId' },
      { align: 'start', class: 'table-header', text: 'Data Inicio', value: 'dtStart' },
      { align: 'start', class: 'table-header', text: 'Data Fim', value: 'dtEnd' },
      { align: 'start', class: 'table-header', text: 'Data Sorteio', value: 'dtDraw' },
      { align: 'start', class: 'table-header', text: 'Data Cadastro', value: 'date' },
      { align: 'center', class: 'table-header', text: 'Qtd. Cupons', value: 'config.sizeLot' },
      { align: 'center', class: 'table-header', text: 'Vendidos', value: 'cuponsSold' },
      // { align: 'center', class: 'table-header', text: 'Resultado', value: 'config' },
      { align: 'center', class: 'table-header', text: 'Status', value: 'status' },
      { align: 'end', class: 'table-header', text: '', value: 'action', sortable: false },
    ]
  }),

  mounted () {
    // this.getItensModalities()
    this.getStart()
  },

  computed: {
    ...mapGetters(namespaceStore, ['totalItens', 'item']),
    listaItens () {
      if (this.selectFilter) {
        return filter(this.$store.getters['sweepstakes/listaItens'], (item) => {
          return item.modalityId === this.selectFilter
        })
      }
      return this.$store.getters['sweepstakes/listaItens']
    },

    listaItensSelect () {
      return [{
        text: 'Selecione um filtro',
        value: ''
      }, ...this.$store.getters['sweepstakes/listaItemSelectMethods'] ]
    },

    itemSelectKeys () {
      return {
        'Sweepstakes:id': '',
        ascertainedMethodId: '',
        cod: '',
        'Sweepstakes:dtDraw': '',
        'Sweepstakes:dtEnd': '',
        'Sweepstakes:dtStart': '',
      }
    }
  },

  methods: {
    ...mapActions(namespaceStore, ['getItens', 'buscarItem', 'deletarItem', 'getItem', 'clearItens', 'updateConfig']),
    ...mapActions('modalities', {
      getItensModalities: 'getItens'
    }),
    init() {},
    getStart() {
      let filter = {
        'Sweepstakes:dtEnd_>=': moment().add(-30, 'minutes').format('YYYY-MM-DD HH:mm:ss')
      }
      this.getItens({ page: this.page, pageSize: this.$store.getters.ItensPaginacao,  _addlFilter: filter })
        .then(result => {
          if (result.data.totalItens < 1) {
            errorSnackbar('Não há dados disponíveis')
          }
        })
        .catch(err => {
          errorSnackbar(err.error)
        })
        .finally(() => this.loadingTable = false)
    },
    buscar(val) {
      this.loadingPag = true
      this.textSearch = val

      this.objCampoBusca['_filter'] = { cod_ilike: `%${val.toUpperCase()}%` }
      this.getItens({ _filter: { cod_ilike: `%${val.toUpperCase()}%` } })
        .then(result => {
          if (result.data.totalItens < 1) {
            errorSnackbar('Não há dados disponíveis')
          }
        })
        .catch(err => {
          errorSnackbar(err.error)
        })
        .finally(() => { this.loadingPag = false })
    },

    editItem (item) {
      this.getItem({ id: item.id }).then((item) => {
        // this.getItens({ page: this.page, pageSize: this.$store.getters.ItensPaginacao })
        Events.$emit('cadastro::openModalCadastro', item)
      }).catch(() => {
        errorSnackbar('Error ao selecionar o item')
      })
    },
    updateConfigurations (item) {
      const vm = this
      this.loadingPag = true
      let dados = Object.assign({}, item)
      let dataFormat = 'DD/MM/YYYY H:mm'
      dados.dtDraw = moment(dados.dtDraw, dataFormat).format('YYYY-MM-DD H:mm')
      dados.dtEnd = moment(dados.dtEnd, dataFormat).format('YYYY-MM-DD H:mm')
      dados.dtStart = moment(dados.dtStart, dataFormat).format('YYYY-MM-DD H:mm')

      if (!item.config.showResult) {
        dados.config.showResult = true
      } else {
        dados.config.showResult = !item.config.showResult
      }

      this.updateConfig(dados).then(() => {
        this.getItens({ page: this.page, pageSize: this.$store.getters.ItensPaginacao }).finally(() => {vm.loadingPag = false})
        successSnackbar('Resultado alterado com sucesso!')
      }).catch(() => {
        errorSnackbar('Error o item')
      })
    },
    getColorSituacao (status) {
      if (status === 'Previsto') return 'black'
      if (status === 'Publicado') return 'blue'
      if (status === 'Sorteado') return 'green'
      if (status === 'Encerrado') return 'red'
      if (status === false || !status) return 'warning'
      if (status === true) return 'green'
      return 'yellow'
    },
  }
}
</script>

<style lang="scss">
  @import '../../../../assets/styles/components/table';
  .v-data-table thead tr th,
  .v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    color: $colorPrimary !important;
  }

  .box-table {
    // border-top: 5px solid $colorPrimary;
    // border-top-left-radius: 0 !important;
    // border-top-right-radius: 0 !important;

    .v-data-table-header-mobile {
      display: none;
    }
  }

  .fab-cadastrar-usuario {
    position: fixed;
    z-index: 1;
    bottom: 25px;
    right: 5px;

    @media (min-width: 599px) {
      display: none !important;
    }
  }
</style>>
